import {Injectable} from '@angular/core';
import {FormArray, FormControl, FormGroup, Validators} from "@angular/forms";
import {ContactInfoFieldConfig} from "../interfaces/field-config.interface";
import {Address} from "../models/address.model";
import {ContactDetailModel} from "../models/contact-detail.model";
import {UpdateContactDetailsModel} from "../models/update-contact-details.model";
import {GdprEmailBodyTag} from "../enums/gdpr/gdpr-email-body-tag.enum";
import {GdprConsentRequestInterface} from "../interfaces/gdpr/gdpr-consent-request.interface";
import {Contact} from "../models/contact.model";
import {SIGNATURE} from "../constants/gdpr-email-signature.constant";
import {GdprEmailSignatureTag} from "../enums/gdpr/gdpr-signature-tag.enum";
import {GeoNamesCountry} from "../models/geonames-country.model";
import {GeoNamesCity} from "../models/geonames-city.model";
import {DropdownOption} from "../interfaces/dropdown-option.interface";
import {ContactInfluenceDegree} from "../enums/contact-influence-degree.enum";
import {CommentData} from "../components/comments/intrefaces/comment-data.interface";
import {AvatarUser} from "../components/avatar-component/models/avatar-user.model";
import {ContactCommentTypePipe} from "../pipes/contact-comment-type.pipe";
import moment from "moment/moment";
import {ContactCommentModel} from "../models/contact-comment.model";
import {ContactCommentTypeEnum} from "../enums/contact-comment-type.enum";

@Injectable()
export class ContactUtilsService {

  /**
   * This function returns an array of influence degree options for a contact.
   * Each option includes a label, value, icon, and tooltip with detailed information.
   *
   * @returns An array of influence degree options.
   */
  influenceDegreeOptions() {
    const influenceDegreeOptions: DropdownOption[] = [
      {
        label: 'Influencer',
        value: ContactInfluenceDegree.INFLUENCER,
        icon: 'assets/icons/info-gray.svg',
        tooltip: {
          header: 'INFLUENCER (THE EXPERT)',
          content: ['Person whose opinions, recommendations, or advice significantly impact the buying decision.',
            'Sometimes, the \'techie\' or geek. Has deep technical knowledge of the product/service, as well as its ' +
            'competitors and industry trends.',
            'Interested in supply, features, specification, maintenance, and applications, including future-proofing. ' +
            'May use jargon.',
            'Motivated by how it works, rather than what it does for the organization.'
          ]
        }
      },
      {
        label: 'User',
        value: ContactInfluenceDegree.USER,
        icon: 'assets/icons/info-gray.svg',
        tooltip: {
          header: 'END USER',
          content: ['Regularly uses/ manages the product/ service. Interested in its performance, features, ' +
          'functionality, ease of use and reliability.',
            'May be relatively junior in the buying group, but often responsible for developing the tender ' +
            'specifications or ITT/ RFP.',
            'Motivated by what\'s best for them, their business function and their team.',
          ]
        }
      },
      {
        label: 'Decider',
        value: ContactInfluenceDegree.DECIDER,
        icon: 'assets/icons/info-gray.svg',
        tooltip: {
          header: 'DECIDER (THE BOSS)',
          content: ['Most senior, ultimate decision-maker, MD, CEO, business owner. Concerned with high-level strategic, ' +
          'organisational outcomes.',
            'Less interested in the details of how we do it, more interested in what they get when they hire us, i.e. ' +
            'the results.',
            'Motivated by a mix of personal and corporate goals, e.g. retire early, protect their bonus, be recognized ' +
            'as a success (\'make me look good\'), see value of stock option rise, boost shareholder value, ' +
            'enter new markets/ defend existing markets, protect the organisation\'s reputation or public image.',
          ]
        }
      },
      {
        label: 'Buyer',
        value: ContactInfluenceDegree.BUYER,
        icon: 'assets/icons/info-gray.svg',
        tooltip: {
          header: 'BUYER (THE MONEY PERSON)',
          content: ['Finance Director, the budget holder. They have their hands on the purse strings, sign the cheques ' +
          'and have the authority to spend money.',
            'They usually work closely with the Boss. Only interested in our product or service from the perspective ' +
            'of cost vs benefit.',
            'Motivated by value for money and getting the best possible deal for their organisation.'
          ]
        }
      },
    ];

    return influenceDegreeOptions;
  }

  /**
   * This method adds a contact info element form group to the form array.
   * if a value is specified, the form group created will be patched with it before adding to the form array
   * @param fieldConfig contact info field config {@link ContactInfoFieldConfig}
   * @param value contact info init value
   */
  buildContactDetailFieldFormGroup<T>(
    fieldConfig: ContactInfoFieldConfig<T>,
    value?: any
  ) {
    // validator of type field
    const typeFormControlValidator = [];
    if (fieldConfig?.type.required) {
      typeFormControlValidator.push(Validators.required);
    }
    // validator of value field
    const valueFormControlValidator =
      fieldConfig.value.pattern ? [Validators.pattern(fieldConfig.value.pattern)] : [];
    if (fieldConfig?.value.required) {
      valueFormControlValidator.push(Validators.required);
    }

    // build contact info form group
    let contactInfoFB = new FormGroup({
      id: new FormControl(),
      [fieldConfig?.type.controlName]: new FormControl(
        [],
        typeFormControlValidator
      ),
      [fieldConfig?.value.controlName]: new FormControl(
        '',
        valueFormControlValidator
      )
    });
    // path the form group with the value specified
    if (value) {
      contactInfoFB.patchValue({
          id: value.id,
          [fieldConfig?.type.controlName]: value[fieldConfig?.type.controlName],
          [fieldConfig?.value.controlName]: value[fieldConfig?.value.controlName]
        }
      );
    }
    return contactInfoFB;
  }

  /**
   * This method initialises the form arrays of contact details fields (phones, emails, social medias)
   * with the specified values
   *
   * @param formArray  form array of the treated field (ex: phones form array)
   * @param contactDetailFieldValues initial values of the treated field (ex: phones of a contacts)
   * @param fieldConfig field config see {@link ContactInfoFieldConfig}
   */
  initContactDetailsFieldFormArray<T, U>(formArray: FormArray, contactDetailFieldValues: U[],
                                         fieldConfig: ContactInfoFieldConfig<T>): void {
    contactDetailFieldValues.forEach(
      (fieldValue) => {
        formArray.push(
          this.buildContactDetailFieldFormGroup(fieldConfig, fieldValue)
        )
      }
    )
  }

  /**
   * This method builds an address form group and patches it with the specified value
   * @param addressValue address value {@link Address}
   * @param countries all countries from backend to help us build GeoNamesObject from it
   * because we have only string alfa to code
   */
  buildAddressFormGroup(addressValue?: Address, countries?: GeoNamesCountry[]) {
    // build a new address form group
    let addressFormGroup = new FormGroup({
      id: new FormControl(),
      street: new FormControl(),
      country: new FormControl(null, Validators.required),
      countryCode: new FormControl(),
      city: new FormControl(),
      state: new FormControl(),
      zip: new FormControl(),
      addressType: new FormControl(),
      cities: new FormControl() // Store the cities related the selected country
    });
    // patch the form group with the specified address value
    if (addressValue) {
      addressFormGroup.patchValue(addressValue);
    }
    if (countries) {
      // convert countries & cities from strings to objects
      addressFormGroup.get("country").setValue(
        countries.filter(value => value.countryCode === addressValue.countryCode)[0] || null
      );

      const cities = addressFormGroup.get("cities").value as GeoNamesCity[];
      if (cities) {
        addressFormGroup.get("city").setValue(
          (addressFormGroup.get("cities").value as GeoNamesCity[])
            .filter(value => value.name === addressValue.city)[0] || null
        );
      }
    }
    return addressFormGroup;
  }

  /**
   * This method merges the updated contact data object from contact form value and initial contact values.
   * It updates only modified properties of contact.
   * @param initialContactDetail initial contact details values (retrieved from DB)
   * @param contactFormValue contact updates (contact form values)
   */
  mergeContactUpdates(
    initialContactDetail: ContactDetailModel,
    contactFormValue: ContactDetailModel
  ): ContactDetailModel {
    // take the initial contact values
    let updatedContact: ContactDetailModel = {...initialContactDetail};

    // patch the initial contact values with new contact values coming from the form
    updatedContact.contact = {...updatedContact.contact, ...contactFormValue.contact};
    updatedContact.participants = contactFormValue.participants;
    updatedContact.addresses = contactFormValue.addresses;
    updatedContact.contactLists = contactFormValue.contactLists;
    updatedContact.phoneNumbers = contactFormValue.phoneNumbers;
    updatedContact.emails = contactFormValue.emails;
    updatedContact.socialMedias = contactFormValue.socialMedias;
    updatedContact.secondaryOwners = contactFormValue.secondaryOwners;

    // return update contact model
    return updatedContact;
  }

  /**
   * This method builds the object that will be sent when calling edit contact endpoint {@link UpdateContactModel}
   * from the specified contact details (containing the updates).
   * @param contactDetails contact details object containing contact updates
   */
  buildContactUpdateModel(contactDetails: ContactDetailModel): UpdateContactDetailsModel {
    let updateContactModel = new UpdateContactDetailsModel();
    updateContactModel.init(contactDetails);
    return updateContactModel;
  }

  /**
   * This method checks if a form control identified by the specified name
   * within the specified form group has errors of the specified validation type.
   * @param formGroup       form group {@link FormGroup}
   * @param controlName     form control name (ex: firstName,...)
   * @param validationType  validation applied to form control (ex: required,...)
   */
  isControlHasError(formGroup: FormGroup, controlName: string, validationType: string): boolean {
    let control = formGroup.get(controlName);
    if (!control) {
      return false;
    }
    return control.hasError(validationType) && (control.dirty || control.touched);
  }

  /**
   * This method makes all controls of the specified form as touched to trigger the validation process
   * (ex: to show mandatory fields)
   * @param form  form group
   */
  makeFormControlsAsTouched = (form: any) => {
    Object.keys(form.controls).forEach(
      ctrlName => {
        if (form.controls[ctrlName].controls) {
          if (Array.isArray(form.controls[ctrlName].controls)) {
            // in case of a form array, call updateFormValidity on each form group of form array
            (form.controls[ctrlName] as FormArray).controls
              .forEach(fc => this.makeFormControlsAsTouched(fc));
          } else {
            // in case of a form group, call updateFormValidity
            this.makeFormControlsAsTouched(form.controls[ctrlName] as FormGroup);
          }
        } else {
          // case of simple form control
          form.controls[ctrlName].markAsTouched();
        }
      }
    );
  }

  /**
   * Replaces the tags with their values in the GDPR email body
   *
   * @param emailBody   GDPR email body (HTML string)
   * @param gdprRequest GDPR request data
   * @param contact     contact data
   */
  replaceTags(emailBody: string, gdprRequest: GdprConsentRequestInterface, contact: Contact) {
    emailBody = emailBody.replaceAll(
      GdprEmailBodyTag.RECIPIENT_NAME,
      this.highlightTagValue(contact.firstName + " " + contact.lastName)
    ).replaceAll(
      GdprEmailBodyTag.SENDER_NAME,
      this.highlightTagValue(gdprRequest.senderFullName)
    ).replaceAll(
      GdprEmailBodyTag.GDPR_SITE_LINK,
      `<a href="#GDPRSiteLink" rel="noopener noreferrer" target="_blank">GDPR consent form</a>`
    ).replaceAll(
      GdprEmailBodyTag.CONTACT_EMAIL,
      this.highlightTagValue(contact.email)
    ).replaceAll(
      GdprEmailBodyTag.EMAIL_SIGNATURE,
      this.emailSignatureValue(gdprRequest)
    );

    return emailBody;
  }
  /**
   * Replaces the tags with their corresponding values in the GDPR email body without applying any additional formatting.
   * @param emailBody   GDPR email body (HTML string)
   * @param gdprRequest GDPR request data containing information like sender name and other details
   * @param contact     Contact data including recipient name and email address
   * @returns           The modified email body with the tags replaced by their corresponding values
   */
  replaceTagsWithoutFormatting(emailBody: string, gdprRequest: GdprConsentRequestInterface, contact: Contact) {
    emailBody = emailBody.replaceAll(
      GdprEmailBodyTag.RECIPIENT_NAME,
      contact.firstName + " " + contact.lastName
    ).replaceAll(
      GdprEmailBodyTag.SENDER_NAME,
      gdprRequest.senderFullName
    ).replaceAll(
      GdprEmailBodyTag.GDPR_SITE_LINK,
      `<a href="#GDPRSiteLink" rel="noopener noreferrer" target="_blank">GDPR consent form</a>`
    ).replaceAll(
      GdprEmailBodyTag.CONTACT_EMAIL,
      contact.email
    ).replaceAll(
      GdprEmailBodyTag.EMAIL_SIGNATURE,
      this.emailSignatureValue(gdprRequest)
    );

    return emailBody;
  }

  /**
   * Returns the HTML representation of the email signature.
   * Used to replace the tag [Email signature] of the email body
   * @param gdprRequest       GDPR request data
   */
  emailSignatureValue(gdprRequest: GdprConsentRequestInterface) {
    return SIGNATURE.replaceAll(GdprEmailSignatureTag.FULL_NAME, gdprRequest.emailSignature.fullName)
      .replaceAll(GdprEmailSignatureTag.JOb_TITLE, gdprRequest.emailSignature.jobTitle)
      .replaceAll(GdprEmailSignatureTag.EMAIL, gdprRequest.emailSignature.email)
      .replaceAll(
        GdprEmailSignatureTag.PRIVATE_PHONE,
        this.phoneTagValue(gdprRequest.emailSignature.privatePhone, GdprEmailSignatureTag.PRIVATE_PHONE)
      )
      .replaceAll(
        GdprEmailSignatureTag.OFFICE_PHONE,
        this.phoneTagValue(gdprRequest.emailSignature.officePhone, GdprEmailSignatureTag.OFFICE_PHONE)
      );
  }


  /**
   * Wraps the given value with <strong> tags, which will bold the text when rendered in HTML.
   * Used to replace the tags of GDPR email body tag with their value
   * @param tagValue value of tag (John Doe is the value of [Sender name])
   */
  highlightTagValue(tagValue: string) {
    return `<strong>${tagValue}</strong>`;
  }

  /**
   * Returns the phone number HTML structure to added in the GDPR email signature.
   *
   * @param phoneNumber - The phone number to be added in the HTML.
   * @param phoneTag     - The phone tag to be treated {@see GdprEmailSignatureTag}
   * @returns A formatted HTML string with the phone number.
   */
  phoneTagValue(phoneNumber: string, phoneTag: GdprEmailSignatureTag) {
    if (!phoneNumber || phoneNumber.trim().length == 0) {
      // add some space in the HTML signature (just for the style purpose)
      return '';
    }
    if (phoneTag == GdprEmailSignatureTag.PRIVATE_PHONE) {
      return `<strong><font color="#0082bf">M:</font></strong>&nbsp;${phoneNumber}`
    } else {
      return `<strong><font color="#0082bf">T:</font></strong>&nbsp;${phoneNumber}`
    }
  }

  /**
   * Transforms contacts comments into generic comments data to be used in the comments component.
   * Each comment is mapped to include formatted details such as tag configuration, content,
   * and a formatted timestamp.
   */
  prepareCommentsData(comments: ContactCommentModel[]): CommentData[] {
    return comments.map(comment => {
      // Get the appropriate text and background colors based on the comment type.
      const tagColors = this.getCommentTagColors(comment.type);

      return {
        id: comment.id,
        // The actual content of the comment.
        content: comment.content,
        // Creator information wrapped in an AvatarUser instance.
        creator: comment.creator ? new AvatarUser(comment.creator): null,
        // Display comment type information with appropriate colors.
        tagConfig: {
          tag: ContactCommentTypePipe.prototype.transform(comment.type),
          textColor: tagColors.textColor,
          backgroundColor: tagColors.backgroundColor
        },
        // Format the timestamp in a human-readable.
        timestamp: moment(comment.modifiedAt).format('MMMM D, YYYY h:mm A [UTC]Z')
      };
    })
  }

  /**
   * Determines the text and background colors for a tag representing a comment type.
   */
  getCommentTagColors(commentType: ContactCommentTypeEnum) {
    switch (commentType) {
      case ContactCommentTypeEnum.COMMENT:
        return { textColor: 'neutral-800', backgroundColor: 'neutral-200' };
      case ContactCommentTypeEnum.REQUEST_CHANGES:
        return { textColor: 'red-800', backgroundColor: 'red-100' };
    }
  }
}

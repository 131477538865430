import { Pipe, PipeTransform } from '@angular/core';
import {transformContactCommentType} from "../utils/pipe-transformation.util";
import {ContactCommentTypeEnum} from "../enums/contact-comment-type.enum";

@Pipe({
  name: 'contactCommentType',
  standalone: true
})
export class ContactCommentTypePipe implements PipeTransform {

  transform(value: ContactCommentTypeEnum) {
    return transformContactCommentType(value);
  }

}
